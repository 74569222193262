/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url(https://use.fontawesome.com/releases/v6.6.0/css/all.css);
body {
    font-family: 'Poppins', sans-serif !important;
    color: $bodyColor;
    line-height: 26px;
    font-weight: 400;
    font-size: 15px;
    vertical-align: baseline;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}





.listar-map-button {
    right: auto;
    /***
      box-shadow: 0 0 65px rgba(0,0,0,.07) inset, 0 0 35px rgba(0,0,0,.05) inset;**/
}

.listar-map-button-text span {
    background-color: #fff;
    color: #252525;
    text-shadow: none;
}


header .footer-wave {
    max-width: 102%;
    width: 100%;
    height: 187.8px;
    left: 0;
    z-index: 1;
    bottom: -67px;
    background: url(https://1.bp.blogspot.com/-NYl6L8pz8B4/XoIVXwfhlNI/AAAAAAAAU3k/nxJKiLT706Mb7jUFiM5vdCsOSNnFAh0yQCLcBGAsYHQ/s1600/hero-wave.png) repeat-x;
    animation: wave 10s cubic-bezier(0.44, 0.66, 0.67, 0.37) infinite;
}

@keyframes wave {
    0% {
        background-position: 0;
    }

    100% {
        background-position: 1440px;
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(163, 177, 198, 0.3), 0 0 0 1em rgba(163, 177, 198, 0.3), 0 0 0 3em rgba(163, 177, 198, 0.03), 0 0 0 5em rgba(163, 177, 198, 0.01);
    }

    100% {
        box-shadow: 0 0 0 1em rgba(163, 177, 198, 0.3), 0 0 0 3em rgba(163, 177, 198, 0.03), 0 0 0 5em rgba(163, 177, 198, 0.03), 0 0 0 8em rgba(163, 177, 198, 0.01);
    }
}



.dedicated-team-model {
    text-align: center;
    margin: 50px auto;
    max-width: 800px;
}

.dedicated-team-model h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.team-model {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.model-step {
    flex: 1;
    min-width: 150px;
    padding: 20px;
    text-align: center;
    background: #f5f5f5;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.model-step:hover {
    transform: translateY(-10px);
}

.model-step .icon {
    font-size: 30px;
    margin-bottom: 10px;
}

.model-step h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.card {
    border: none;
}

.accordion .btn {
    background: linear-gradient(to right, #9d2482, #1513c1) !important;
    color: #fff;
    margin-top: 20px;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
}

.accordion .btn::before {
    display: none;
}

.accordion .btn:hover {
    text-decoration: none;
}

.accordion .btn {
    text-decoration: none;
}

.accordion .card-header {
    border: none;
}

.card-header {
    padding: 0 0 30px 0 !important;
}

.card-body {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
}

button,
input,
select,
a {

    &:active,
    &:focus {
        outline: none;
    }
}

a {
    color: $primaryColor;
    transition: $transition;

    &:hover {
        color: $secondaryColor;
        text-decoration: unset;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $titleFont;
    color: $titleColor;
    margin: 0 0 26px;
    line-height: 1.2;
}

p {
    margin: 0 0 20px;
    line-height: 28px;
    font-size: 16px;
}

h1 {
    font-size: 70px;
    font-weight: 700;
}

h2 {
    font-size: 36px;
    font-weight: 700;
}

h3 {
    font-size: 28px;
    font-weight: 700;
}

h4 {
    font-size: 24px;
    font-weight: 700;
}

h5 {
    font-size: 18px;
    font-weight: 700;
}

h6 {
    font-size: 16px;
    font-weight: 700;
}

ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

/*--
    - Text color
-----------------------------------------*/
.white-color {
    color: $whiteColor !important;
}

.primary-color {
    color: $primaryColor !important;
}

.secondary-color {
    color: $secondaryColor !important;
}

.purple-color {
    color: $purpleColor !important;
}

.black-dark {
    background: #131313 !important;
}

.black-bg {
    background: #000000 !important;
}

/*--
    - Background color
-----------------------------------------*/
.gray-bg {
    background: $graybg;
}

.sec-bg {
    background: $secbg;
}

.white-bg {
    background: $whiteColor;
}

.primary-bg {
    background: $primaryColor;
}

.secondary-bg {
    background: $secondaryColor;
}

/*--
    - Background Image
-----------------------------------------*/
.bg1 {
    background-image: url(../img/bg/project-bg.webp);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-color: #000060;
}

.bg2 {
    background-image: url(../img/bg/project-bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #03032c;
}

.bg5 {
    background-image: url(../img/bg/cta-bg1.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bg7 {
    background: url(../img/bg/project-bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #000060;
}

.bg17 {
    background-image: url(../img/about/about-2-67ac84387d55e.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #000060;
}

.bg18 {
    background-image: url(../img/bg/project-bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #00002c;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
    overflow: hidden
}

.clear {
    clear: both;
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.no-border {
    border: none;
}

.no-shadow {
    box-shadow: none;
    text-shadow: none;
}

.no-padding {
    padding: 0;
}

.padding-0 {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.margin-0 {
    margin: 0;
    font-size: 15px;
}

.no-gutter {
    margin-left: 0;
    margin-right: 0;

    >[class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}

.container-fluid {
    padding-left: 60px;
    padding-right: 60px;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.zIndex {
    position: relative;
    z-index: 9;
}

.zIndex1 {
    position: relative;
    z-index: 1;
}

.y-middle {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.y-bottom {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

/*Video Icon*/
.popup-video,
.popup-videos {
    cursor: pointer;
}

.media-icon {
    position: relative;
    display: inline-block;
    z-index: 1;

    .popup-videos {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $whiteColor;
        background: $primaryColor;
        width: 70px;
        height: 70px;
        line-height: 70px;
        border-radius: 100%;
        text-align: center;

        &:before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            animation: pulse-border 1500ms ease-out infinite;
            background-color: #1042C0;
            transition: 0.3s ease all;
        }

        i {
            &:before {
                font-size: 30px;
                line-height: 70px;
                transition: 0.3s ease all;
            }
        }

        &:hover {
            &:before {
                background: $primaryColor;
            }
        }
    }

    &.video-item {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.tab-videos {
    .popup-videos {
        color: #de5022;
        display: flex;
        overflow: hidden;

        i {
            min-width: 35px;
            height: 35px;
            line-height: 32px;
            border: 2px solid #de5022;
            border-radius: 50%;
            text-align: center;
            font-size: 14px;
            float: left;
            margin-right: 18px;
        }

        &:hover {
            color: #de5022;
        }

        .title {
            font-size: 22px;
            line-height: 34px;
            font-weight: 600;
            color: $titleColor3;

            &:hover {
                color: $orangeColor;
            }
        }
    }
}

.animate-border {
    background-image: linear-gradient(180deg, $secondaryColor 0%, $primaryColor 100%);
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    i {
        font-size: 20px;
        line-height: 80px;
        display: block;
        color: $whiteColor;
    }

    &:before {
        content: "";
        border: 2px solid $primaryColor;
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        animation: zoomBig 3.25s linear infinite;
        -webkit-animation-delay: .75s;
        animation-delay: .75s;
        pointer-events: none;
    }

    &:after {
        content: "";
        border: 2px solid $primaryColor;
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        animation: zoomBig 3.25s linear infinite;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        pointer-events: none;
    }

    &.white-color {
        background: $whiteColor;

        i {
            color: $secondaryColor;
        }

        &:before {
            border: 2px solid $whiteColor;

        }

        &:after {
            border: 2px solid $whiteColor;
        }

        &.style3 {
            margin-bottom: 65px;
        }
    }
}

.readon {
    color: $whiteColor;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: all 0.4s;
    background-color: transparent;

    &.style1 {
        padding: 14px 40px 14px 40px;
        background-image: linear-gradient(250deg, $primaryColor 19%, $secondaryColor 100%);
        border-radius: 30px 30px 30px 30px;

        &:hover {
            background-image: linear-gradient(180deg, $secondaryColor 19%, $primaryColor 100%);
            color: $whiteColor;
        }
    }

    &.learn-more {
        padding: 8px 20px;
        background: linear-gradient(to right, #9d2482, #1513c1);
        font-weight: 550 !important;
        font-size: 17px !important;
        text-transform: capitalize !important;
        border-radius: 30px 30px 30px 30px;
        transition: all .4s ease-in-out;
        z-index: 5;

        &:hover {
            // background: #4c2f91;
            color: $whiteColor;
            // transition: all .4s ease-in-out;
        }

        &.sub-con {
            background-image: linear-gradient(250deg, #4E95ED 0%, #03228F 100%);
            padding: 18px 36px 16px 36px;
            font-size: 14px;
            font-weight: 500;

            &:hover {
                background-image: linear-gradient(250deg, #03228F 0%, #4E95ED 100%);
                color: $whiteColor;
            }
        }

        &.submit {
            background-image: linear-gradient(90deg, #03228F 0%, #4E95ED 100%);
            padding: 16px 40px 16px 40px;
            font-weight: 500;
            font-size: 16px;
            border: none;

            &:hover {
                background-image: linear-gradient(200deg, #03228F 0%, #4E95ED 100%);
                color: $whiteColor;
            }
        }

        &.post {
            background-image: linear-gradient(50deg, #03228f 10%, #0e73e4 100%);
            padding: 12px 30px;
            font-size: 14px;
            font-weight: 500;

            &:hover {
                background-image: linear-gradient(50deg, #0e73e4 10%, #03228f 100%);
                color: #fff;
            }
        }

        &.pdf {
            i {
                margin-left: 10px;
            }
        }

        &.contact-us {
            border-radius: 8px 8px 8px 8px !important;
        }

        &.learn-btn {
            background-image: linear-gradient(90deg, #03228F 0%, #1D62F0 100%);
            border-radius: 3px 3px 3px 3px !important;

            &:hover {
                background-image: linear-gradient(180deg, #03228F 19%, #4E95ED 100%);
            }
        }

        &.slider-btn {
            background: $whiteColor;
            color: $primaryColor;
            padding: 15px 37px;
            border-radius: 8px 8px 8px 8px !important;

            &:hover {
                background-image: linear-gradient(250deg, #4E95ED 19%, #03228F 100%);
                color: $whiteColor;
            }
        }
    }

    &.started {
        padding: 8px 20px;
        background: linear-gradient(to right, #9d2482, #1513c1);
        font-weight: 550;
        border-radius: 30px 30px 30px 30px;

        &:hover {
            background: #03228F;
            color: $whiteColor;
            border: 1px solid #03228F;
        }

        &.get-new {
            &:hover {
                background: rgba(0, 0, 0, 0) linear-gradient(79deg, rgb(221, 76, 35) 0%, rgb(242, 124, 30) 63%, rgb(242, 124, 30));
            }
        }

        &.get-ready {
            background-image: linear-gradient(90deg, #03228f 0, #0e73e4 100%);
            border-radius: 3px;
            text-transform: uppercase;

            &:hover {
                opacity: 0.9;
            }
        }

        &.get-ready2 {
            border-radius: 5px 5px 5px 5px;
        }

        &.get-ready3 {
            border-radius: 5px 5px 5px 5px;
            text-transform: uppercase;
            font-weight: 500;
        }

        &.get-ready4 {
            background-image: linear-gradient(80deg, #6e71db 0%, #9c9ff9 100%);
            padding: 18px 40px 18px 40px;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            border-radius: 3px 3px 3px 3px;
        }
    }

    &.lets-talk {
        padding: 14px 40px 14px 40px;
        background-image: linear-gradient(250deg, #4E95ED 19%, #03228F 100%);
        border-radius: 30px 30px 30px 30px;

        &:hover {
            background-image: linear-gradient(180deg, #03228F 19%, #4E95ED 100%);
            color: $whiteColor;
        }
    }

    &.buy-now {
        padding: 10px 40px 10px 40px;
        background-image: linear-gradient(170deg, #03228F 0%, #4E95ED 100%);
        border-radius: 30px 30px 30px 30px;
        text-transform: uppercase;
        position: relative;
        top: 25px;

        &:hover {
            background-image: linear-gradient(360deg, #03228F 0%, #4E95ED 100%);
            color: $whiteColor;
        }

        &.get-in {
            padding: 8px 20px;
            font-weight: 550 !important;
            font-size: 17px !important;
            text-transform: capitalize;
            color: $whiteColor;
            background: linear-gradient(to right, #9d2482, #1513c1);
            transition: all .4s ease-in-out;

            &:hover {
                // background: #4c2f91;
                // color: $whiteColor;
                // transition: all .4s ease-in-out;
            }
        }

        &.table-btn {
            background-image: linear-gradient(90deg, #032390 0%, #032390 100%);
            color: $whiteColor;
            border-radius: 3px 3px 3px 3px;
            font-weight: 500;

            &:hover {
                background-image: linear-gradient(90deg, #0D6ADA 0%, #0D6ADA 100%);
            }
        }

        &.sl-btn {
            background: rgba(0, 0, 0, 0) linear-gradient(90deg, rgb(3, 34, 143) 0%, rgb(29, 98, 240) 100%);
            color: $whiteColor;
            border-radius: 4px;
            font-weight: 500;
            font-size: 18px;
            text-transform: capitalize;
            padding: 17px 28px;

            &:hover {
                background-image: linear-gradient(90deg, #0D6ADA 0%, #0D6ADA 100%);
            }
        }

        &.price {
            background-image: linear-gradient(170deg, #03228F 0%, #4E95ED 100%);
            border-radius: 8px 8px 8px 8px;

            &:hover {
                background-image: linear-gradient(200deg, #03228F 0%, #4E95ED 100%);
            }
        }

        &.pricing {
            &:hover {
                background-image: linear-gradient(170deg, #03228F 0%, #4E95ED 100%);
                color: $whiteColor;
            }
        }

    }

    &.view-more {
        padding: 12px 25px 12px 25px;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: #111111;
        background-color: #FFFFFF;
        border-radius: 30px 30px 30px 30px;
    }

    &.discover {
        font-size: 16px;
        font-weight: 500;
        color: #032491;
        padding: 17px 30px 17px 30px;
        background: transparent;
        background-image: linear-gradient(90deg, #FFFFFF 19%, #FFFFFF 100%);
        border-radius: 4px 4px 4px 4px;

        &.started {
            padding: 16px 30px 16px 30px;

            &:hover {
                background-image: linear-gradient(90deg, #F6F7F9 19%, #F6F7F9 100%);
                color: #032491;
            }
        }

        &:hover {
            background-image: linear-gradient(90deg, #F6F7F9 19%, #F6F7F9 100%);
        }

        &.more {
            background-image: linear-gradient(90deg, #032491 19%, #032491 100%);
            padding: 16px 30px 16px 30px;
            color: $whiteColor;

            &:hover {
                background-image: linear-gradient(90deg, #0D6ADA 19%, #0D6ADA 100%);
                color: $whiteColor;
            }
        }
    }

    &.qoute-btn {
        font-size: 16px;
        padding: 15px 32px;
        border-radius: 3px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        color: $titleColor;
        border: 1px solid $orangeColor;
        background: transparent;

        &:hover {
            color: $whiteColor;
            background-image: linear-gradient(150deg, #dd4c23 19%, #f27c1e 100%);
        }
    }

    &.reply {
        cursor: pointer;
        display: inline-block;
        position: relative;
        transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        padding: 3px 20px 6px;
        line-height: 22px;
        border-radius: 30px;
        font-size: 13px;
        font-weight: 500;
        color: #ffffff !important;
        background-image: linear-gradient(50deg, #03228f 10%, #0e73e4 100%);
        z-index: 1;
        border: none;

        &:hover {
            background-image: linear-gradient(50deg, #0e73e4 10%, #03228f 100%);
            color: #ffffff;
            opacity: 0.99;
        }
    }

    &.more-about {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 16px 47px 16px 30px;
        color: $whiteColor;
        background-color: transparent;
        background-image: linear-gradient(110deg, #6E71DB 0%, #9C9FF9 100%);
        border-radius: 3px 3px 3px 3px;

        i {
            position: absolute;
            font-size: 10px;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;
            color: #fff;
        }

        &:before {
            content: "\f054";
            position: absolute;
            font-family: "FontAwesome";
            font-size: 10px;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;
            color: #fff;
        }

        &:hover {
            background-color: transparent;
            background-image: linear-gradient(240deg, #6E71DB 0%, #9C9FF9 100%);
        }
    }
}

.dots {
    list-style: disc;
    margin: 0 0 1.5em 3em !important;
}

.add-btn {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    padding: 10px 40px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 500;
    background-image: linear-gradient(90deg, #03228f 0%, #0e73e4 100%);
    color: #ffffff;
    z-index: 1;
    border: none;

    &:hover {
        color: #fff;
        background-image: linear-gradient(90deg, #0e73e4 19%, #03228f 100%);
        z-index: 1;
    }
}

.project-item .vertical-middle {
    position: relative;
    z-index: 9;
}

.vertical-middle {
    display: table;
    height: 100%;
    width: 100%;
}

.vertical-middle-cell {
    display: table-cell;
    vertical-align: middle;
}

blockquote {
    margin: 35px 0px;
    padding: 40px 60px;
    color: #666;
    position: relative;
    background: rgba(0, 0, 0, 0.01);
    font-weight: normal;
    font-style: italic;
    text-align: left;
    clear: both;
    font-weight: 400;
    border-radius: 8px;

    cite {
        &:before {
            content: "";
            font-size: 28px;
            color: #ff5421;
            padding-bottom: 0px;
            display: inline-block;
            background: $primaryColor;
            height: 2px;
            width: 40px;
            font-weight: 400;
            text-align: center;
            top: -4px;
            margin-right: 10px;
            position: relative;
        }
    }

    &::before {
        content: "\f129";
        font-size: 35px;
        color: $primaryColor;
        padding-bottom: 0;
        display: inline-block;
        font-family: flaticon;
        font-weight: 400;
        text-align: center;
        top: 0;
        background: 0 0;
        transform: rotate(180deg);
    }
}

.arrow-btn {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -15%);

    a {
        font-weight: 400;
        font-size: 17px;
        border-color: $orangeColor;
        border-style: solid;
        border-width: 1px;
        margin: 0px;
        border-radius: 23px;
        padding: 12px 15px;
        color: $orangeColor;

        span {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;
            margin: -4px 0 0 -4px;
            border-radius: 50%;
            background: $orangeColor;
        }
    }
}

// Services-btn
.services-btn2 {
    a {
        position: relative;
        display: inline-block;
        transition: all ease .3s;
        font-weight: 500;
        color: #032390;

        &:after {
            position: absolute;
            width: 30px;
            height: 1px;
            background: #0b70e1;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            transition: 0.4s;
            opacity: 0;
        }

        &:hover {
            &:after {
                right: -40px;
                opacity: 1;
            }
        }
    }
}

.services-btn3 {
    a {
        position: relative;
        color: $titleColor;
        display: inline-block;
        transition: all 0.4s cubic-bezier(0.4, 0, 1, 1);
        text-decoration: none;

        &:after {
            position: absolute;
            right: 0;
            top: 50%;
            font-size: 15px;
            font-weight: 500;
            color: $orangeColor;
            transform: translateY(-50%);
            transition: all 0.5s ease;
        }

        &:hover {
            color: $orangeColor;

            &:after {
                right: -28px;
                content: "\f110";
                transition: all 0.9s ease;
            }
        }
    }
}

// Submit Button CSS
.submit-btn {
    box-shadow: 0 28px 50px 0 rgba(0, 0, 0, .05);
    outline: 0;
    border: none;
    padding: 18px 18px 18px 18px;
    border-radius: 3px;
    font-size: 16px;
    display: inline-block;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-image: linear-gradient(160deg, #03228F 0%, #0B70E1 77%);
    transition: all .3s ease;
    position: relative;
    overflow: hidden;
    width: 100%;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }

    &.orange-btn {
        background-image: linear-gradient(160deg, #F27C1E 0%, #DD4C23 79%) !important;
    }
}

.submit {
    color: #FFFFFF;
    background-color: transparent;
    background-image: linear-gradient(95deg, #03228F 0%, #4E95ED 100%);
    padding: 18px 40px 17px 40px;
    font-size: 15px;
    border-radius: 30px 30px 30px 30px;
    border: none;

    &:hover {
        opacity: 0.99;
    }

    &.sub-small {
        padding: 17px 40px 17px 40px;

        &:hover {
            color: $whiteColor;
            background-image: linear-gradient(290deg, #03228F 0%, #4E95ED 100%);
            opacity: unset;
        }
    }
}

// Section Title
.sec-title {
    .sub-text {
        position: relative;
        padding-left: 95px;
        display: inline-block;
        line-height: 28px;
        font-size: 15px !important;
        letter-spacing: 0.5px;
        font-weight: 550 !important;
        color: #03228F;
        text-transform: uppercase;
        margin-bottom: 10px;

        &:after {
            content: "";
            position: absolute;
            border: 0;
            height: 4px;
            width: 50px;
            background-color: #03228F;
            z-index: 1;
            margin-left: 0;
            left: 24px;
            top: 12px;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 12px;
            height: 4px;
            width: 12px;
            background-color: #03228F;
        }

        &.new {
            color: $whiteColor;
            font-size: 15px;
            padding-left: 0;

            &:before {
                display: none;
            }

            &:after {
                display: none
            }
        }

        &.new-text {
            padding-left: 0;
            margin-bottom: 7px;
            color: $whiteColor;

            &:before {
                display: none;
            }

            &:after {
                display: none
            }
        }

        &.style2 {
            &:after {
                background-color: $primaryColor;
            }

            &:before {
                background-color: $primaryColor;
            }
        }

        &.style-bg {
            background-color: rgba(201, 58, 135, 0.21);
            display: inline-block;
            padding: 5px 25px;
            color: #4c2f91;
            border-radius: 30px;
            text-transform: uppercase;

            &:before {
                display: none;
            }

            &:after {
                display: none
            }
        }

        &.style4-bg {
            color: #03228F;

            &:before {
                background-color: #FD6509;
            }

            &:after {
                background-color: #FD6509;
            }
        }

        &.choose {
            font-size: 16px;
            font-weight: 500;
        }

    }

    .title {
        font-size: 48px;
        font-weight: 550;
        line-height: 52px;
        color: #4d3092;
        margin: 0 0 15px 0;
    }

    .desc {
        font-size: 15px;
        font-weight: 400;
        margin: 0;

        &.fixed-width {
            max-width: 525px;
        }
    }

    .desc-big {
        font-size: 28px;
        line-height: 48px;
        font-weight: 500;
        margin: 0;
    }
}

.sec-title2 {
    position: relative;

    .sub-text {
        margin: 0 0 10px;
        display: block;
        line-height: 28px;
        font-size: 14px;
        font-weight: 400;
        color: #03228F;
        text-transform: uppercase;

        &.white-color {
            color: $whiteColor;
        }

        &.gold-color {
            font-weight: 600;
            color: #F27C1E;
        }

        &.contact {
            color: #1273EB;
        }

        &.orange-color {
            color: $orangeColor !important;
        }

        &.style-bg {
            background-color: rgb(201 58 135 / 21%);
            display: inline-block;
            padding: 5px 25px;
            color: #4c2f91;
            border-radius: 30px;
            text-transform: uppercase;

            &.white-color {
                color: $whiteColor;
            }

            &.con-bg {
                background-color: #03228F;
            }
        }
    }

    .title {


        font-family: 'Poppins', sans-serif !important;
        font-size: 48px;
        font-weight: 550;
        line-height: 58px;
        background-color: #FF3CAC;
        
        background: linear-gradient(225deg, #aa0655, #6f3aa0 33%, #145f94 66%, #7d146b);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-color: #f5f5fa;
        -webkit-text-fill-color: #00007000;
        max-width: 100%;
        margin: 0 auto;
        text-transform: capitalize;

        &.white-color {
            color: $whiteColor;
        }

        &.testi-title {
            max-width: unset;

            &.new {
                color: #102B3E;
            }
        }

        &.title2 {
            font-size: 48px;
            font-weight: 700;
            line-height: 54px;
            text-transform: capitalize;
            color: #000;

        }

        &.title3 {
            color: #102B3E;
            max-width: 430px;
            margin: 0;
        }

        &.title4 {
            max-width: 660px;
        }

        &.title5 {
            color: #102B3E;
        }

        &.title6 {
            font-weight: 600;
            max-width: unset;
        }

        &.title7 {
            color: #102B3E;
            max-width: unset;
        }

        &.orange-color {
            color: $orangeColor !important;
        }
    }
 
    .heading-line {
        position: relative;
        background: linear-gradient(to right, #9d2482, #1513c1);
        height: 5px;
        width: 90px;
        border-radius: 30px;
        margin: 20px auto;

        &:before {
            background-color: #1940AA;
            content: '';
            position: absolute;
            left: 0;
            top: -2.7px;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            -webkit-animation-duration: 3s;
            animation-duration: 3s;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            animation-direction: alternate-reverse;
            -webkit-animation-direction: alternate-reverse;
            -webkit-animation-name: watermark-animate;
            animation-name: watermark-animate;
        }
    }

    .desc {
        font-size: 15px;
        line-height: 28px;
        font-weight: 400;
        color: #454545;
        max-width: 100%;
        margin: 15px auto 0;

        &.desc-text {
            max-width: 540px;
        }

        &.white-color {
            color: #fff;
        }

        &.desc2 {
            max-width: 700px;
            margin: 0;
        }

        &.desc3 {
            max-width: 930px;
        }
    }

    .desc-big {
        font-size: 18px;
        line-height: 31px;
        color: $whiteColor;
    }
}

.sec-title3 {
    .sub-text {
        font-size: 19px;
        line-height: 1.4;
        font-weight: 700;
        color: $whiteColor;
        display: block;
        margin-bottom: 20px;
    }

    .title {
        font-size: 52px;
        font-weight: 700;
        color: #fff;
        line-height: 54px;
        margin-bottom: 15px;
        letter-spacing: 0.8px;
        font-weight: bolder !important;
    }

    .desc {
        font-size: 19px;
        font-weight: 500;
        color: #fff;
        margin: 0;
    }
}

.sec-title4 {
    .title-img {
        img {
            margin: 0px 0px 0px 15px;
        }
    }

    .sub-title {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        color: #787BF2;
        display: block;
        margin: -26px 0px 10px 0px;
    }

    .sub-text {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 500;
        color: #03228F;
        margin: 0 0 10px;
        display: block;
        text-transform: uppercase;
    }

    .title {
        font-size: 36px;
        line-height: 1.3;
        font-weight: 700;
        color: $titleColor;
        margin: 0 0 17px;
    }

    .heading-line {
        height: 5px;
        width: 90px;
        background: #B9C7F6;
        margin: 0px;
        position: relative;
        border-radius: 30px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -2.7px;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #0D66D5;
            -webkit-animation-duration: 3s;
            animation-duration: 3s;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            animation-direction: alternate-reverse;
            -webkit-animation-direction: alternate-reverse;
            -webkit-animation-name: watermark-animate;
            animation-name: watermark-animate;
        }
    }

    .desc {
        margin: 0;

        &.desc-big {
            font-size: 20px;
            line-height: 30px;
            margin: 26px 0px 30px 0px;
        }
    }
}

.sec-title5 {
    .logo-icon {
        margin-bottom: 15px;

        img {
            width: 71px;
        }
    }

    .title {
        font-size: 38px;
        line-height: 44px;
        font-weight: 700;
        color: #4c2f91;
        margin-bottom: 10px;

        span {
            color: #F9780F;
        }
    }

    .desc {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
        color: $bodyColor;
        max-width: 100%;
        margin: 0 auto;
    }

    .desc2 {
        font-size: 18px;
        line-height: 1.7;
        font-weight: 400;
        color: #102B3E;
    }
}

.sec-title6 {
    .sub-text {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 32px;
        color: #F24C1A;
        display: block;
        margin-bottom: 5px;

        &.new-text {
            margin-bottom: 10px;
        }
    }

    .title {
        font-size: 52px;
        font-weight: 700;
        line-height: 1.4;
        color: #102B3E;
        margin-bottom: 5px;

        &.new-title {
            max-width: 700px;
            margin: 0 auto;
        }
    }

    .desc {
        padding-right: 125px;
    }
}

.sec-right {
    position: absolute;
    right: -17%;
    top: 45%;
    transform: rotate(90deg);
    z-index: 1;

    .title {

        font-size: 42px;
        line-height: 46px;
        font-weight: 700;
        color: $titleColor;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }
}

.sec-left {
    transform: rotate(-90deg) translatex(-50%);
    transform-origin: left;
    position: absolute;
    left: -10%;
    top: 50%;
    z-index: 1;

    .title {
        font-size: 42px;
        line-height: 46px;
        font-weight: 700;
        color: $titleColor;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }
}

// others common css here :)
.check-square {
    li {
        position: relative;
        padding-left: 20px;
        line-height: 35px;
        border: none;
        font-size: 14px;
        font-weight: 400;
        color: #454545;

        &:before {
            position: absolute;
            left: 0;
            z-index: 0;
            content: "\f058";
            font-weight: 400;
            font-family: 'FontAwesome';
            color: rgb(112 16 187);
        }
    }
}

.shape-part {

    .right-side,
    .left-side {
        position: absolute;
        bottom: 0px;
        left: 0;
    }

    .right-side {
        left: auto;
        right: 0;
    }
}

.rs-step {
    background: url(../../assets/img/bg/steps-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px 10px 10px 10px;
    padding: 258px 0;

}

.owl-nav {
    >div {
        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 70px;
            height: 70px;
            display: inline-block;
            background: #f6f2ed;
            line-height: 65px;
            text-align: center;
            border-radius: 50%;

            >img {
                min-width: 10px;
                max-width: 25px;
            }
        }
    }

    .owl-next {
        >span {
            right: -35px;
        }
    }

    .owl-prev {
        >span {
            left: -35px;
        }
    }
}

.testimonial-active {
    button {
        &.slick-arrow {
            opacity: 1;
            visibility: visible;
            position: absolute;
            top: auto;
            bottom: 0;
            transform: translateY(100px);
            background: #f6f2ed;
            transition: .4s;

            &.slick-prev {
                left: 0;
            }

            &.slick-next {
                left: 85px;
            }

            &:hover,
            &:focus {
                background: #096bd8;
                color: #fff;
                transition: .4s;
            }
        }
    }
}


[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
    margin-left: 0;
}

.pagination-part {
    display: inline-block;
    border: 1px solid #f9f9f9;
    padding: 11px 0;
    border-radius: 3px;
    box-shadow: 0 0 30px rgba(0, 0, 0, .05);
    background: #fff;

    li {
        padding: 2px 20px 0 21px;
        line-height: 23px;
        font-size: 18px;
        text-decoration: none;
        transition: all .4s ease-in-out 0s;
        text-align: center;
        background: 0 0;
        color: #000;
        display: inline-block;
        vertical-align: top;
        border-right: 1px solid #e6e6e6;

        a {
            color: $bodyColor;

            &:hover {
                color: $primaryColor;
            }
        }

        &:last-child {
            border-right: unset;
        }
    }

    .next-page {
        position: relative;
        transition: all .4s ease-in-out 0s;
        padding-right: 12px;

        &:before,
        &:after {
            content: "\f105";
            position: absolute;
            top: 6px;
            right: 0;
            line-height: 16px;
            font-family: "FontAwesome";
            font-size: 12px;
        }

        &:before {
            right: 4px;
        }
    }

    .active {
        a {
            color: $primaryColor;
        }
    }
}

select {
    cursor: pointer;
}

.watermark-animate {
    -webkit-animation: watermark-animate 8s infinite;
    animation: watermark-animate 8s infinite;
    animation-direction: alternate-reverse;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes watermark-animate {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}



/*--
    -Removeable
-----------------------------------------*/
.grey-bg-2 {
    background: $grey-2;
}

.black-bg {
    background: $black;
}

.theme-bg {
    background: $theme-color;
}

.theme-bg {
    background: $theme-color;
}

.footer-bg {
    background: $footer-bg;
}

.black-color {
    color: $black;
}

.theme-color {
    color: $theme-color;
}



@media #{$laptop} {

    .sec-left,
    .sec-right {
        display: none;
    }
    .whychoos-img{
    background-position: 80% !important;
    min-height: 740px !important;
    top: -30px !important;
    }
   
    
    
   
}


@media #{$lg} {
    .sec-title3 .title {
        font-size: 33px;
        line-height: 35px;
    }

    .sec-title6 .title.new-title {
        font-size: 35px;
    }

    .readon.buy-now.get-in {
        padding: 8px 20px;
    }
    .rs-process.style6 .tab-area .nav li{
        padding: 10px 10px !important;
    }
    .row_rev_sec .row{
        flex-direction: column-reverse;
    }
    
}

@media #{$only_lg} {
    .sec-title .title {
        font-size: 27px;
    }

    .sec-title4 .title {
        font-size: 28px;
        line-height: 1.2;
    }
    .counter_inner_sec {
        padding: 30px 20px !important;
    }
 
    .whychoos_inner{display:none;}
    .row_rev_sec .row{
        flex-direction: column-reverse;
    }
}

@media #{$md} {

    .sec-title .title,
    .sec-title2 .title {
        font-size: 32px;
        line-height: 38px;
    }

    .sec-title4 .title {
        font-size: 32px;
        line-height: 38px;
    }

    .sec-title5 .title.title2 {
        font-size: 32px;
        line-height: 38px;
    }

    .sec-title6 .title.new-title {
        font-size: 26px;
    }

    .line_pure {
        display: none;
    }
    .blog-item.mb-44 img{
        width: unset !important;
        height: unset !important;
    }
    .rs-inner-blog .blog-item{
       flex-direction: column;
    }
    .vp-container{
        padding: 10px 0;
        background-color: #fff !important;
    }
    img.bread_main_img.metaqualt_img{
        object-fit: cover;
        object-position: top;
    }
    img.bread_main_img.metaqualt_img {margin: 10px 0;}
    .vjs-control-bar{
        margin-bottom: 0px !important;
    }
    .video-js .vjs-tech:focus-visible{
        outline: none;
    }
    .vedio_lifemetaqualt .video-js .vjs-control-bar{
       width: 65% !important;
      margin-left: 121px;
    }
    .rs-blog .blog-item .image-wrap{
        width: 100% !important;
    }
    .rs-inner-blog .blog-item .blog-content{
        width: 100% !important;
    }
    .widget-area_whitepaper .blog-item{
        margin: unset;
    }
    
   
}


@media #{$sm} {
    .rs-blog .blog-item .image-wrap{
        width: 100% !important;
    }
    .rs-inner-blog .blog-item .blog-content{
        width: 100% !important;
    }
    .sec-title .title {
        font-size: 26px;
    }

    .sec-title4 .title {
        font-size: 26px;
    }

    .sec-title5 .title {
        font-size: 28px;
    }

    .sec-title6 {
        .sub-text {
            font-size: 16px;
            line-height: 30px;
        }

        .title {
            font-size: 31px;
        }

        .title.new-title {
            font-size: 26px;
        }
        .video-js{
            height: 500px !important;
        }
       
    }

    .rs-step {
        padding: 170px 0;
    }

    .line_rg {
        padding-right: 0;
    }
    .main_menu_inner.mega_menu::after{
        display: none;
    }
    .row_rev_sec .row{
        flex-direction: column-reverse;
    }
    img.bread_main_img.metaqualt_img{
       padding: 10px 0;
    }
    .vjs-control-bar{
        margin-bottom: 0px;
    }
}

@media #{$xs} {
    .sec-title6 {
        .title {
            font-size: 26px;
            line-height: 1.2;
        }

        .title.new-title {
            font-size: 23px;
        }
        .Innovation .bread_main_img{
            margin: 10px 0;
        }
        .vjs-control-bar{
            margin-bottom: 0px;
        }
        .video-js{
            height: 500px !important;
        }
    
    }

    .line_rg {
        padding-right: 0 !important;
    }

    .br_top {
        margin-right: 0 !important;
    }
    .row_rev_sec .row{
        flex-direction: column-reverse;
    }
    img.bread_main_img.metaqualt_img{
        padding: 10px 0;
    }
   
}

@media #{$mobile} {
    .container-fluid {
        padding: 0 15px;
    }

    .sec-title .title {
        font-size: 24px;
    }

    .sec-title2 .title,
    .sec-title3 .title {
        font-size: 27px;
    }
    .bg18 .sec-title2 .title{
        font-size: 33px !important;
        line-height: 38px !important;
    }
    .rs-services.style3 .services-item .services-content .services-text .title{
        font-size: 20px !important;
    }

    .rs-call-us .sec-title3 .title {
        font-size: 32px !important;
        line-height: 38px;
    }

    .rs-process.style4 .rs-addon-number .number-text .number-area {
        left: 0;
    }

    .sec-title6 .title.new-title {
        font-size: 21px;
    }

    .readon.started {
        padding: 10px 25px;
    }
    .rs-about  img{
        padding-bottom: 30px;
    }
    .row_rev_sec .row{
        flex-direction: column-reverse;
    }
    .rs-process.style4 .rs-addon-number .number-text .number-area{
        width: 38px !important;
    }
    .rs-process .rs-addon-number .number-text .number-title .title{
        font-size: 16px !important;
        line-height: 20px !important;
    }
    img.bread_main_img.metaqualt_img{
       padding: 10px 0;
    }
    .vjs-control-bar{
        margin-bottom: 0px;
    }
    .video-js{
        height: 500px !important;
    }
    .vedio_lifemetaqualt .video-js{
        margin: 10px auto;
        background-color: #fff !important;
        width: 100%;
    }
   
   
    
}

.w-80 {
    width: 80%;
}

.rs-businessmodels {
    background: url(../img/bg/cta-bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


.cta-bg2 {
    background: url(../img/newsletter/cta-bg-2-67ac6646916f6.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.fz-36 {
    font-size: 36px !important;
    line-height: 40px !important;
}

.box-shadow {
    box-shadow: 0 0 30px rgb(165 181 209 / 78%);
    background: #fff;
    padding: 50px 20px;
}

.bg5 {

    .heading-line {
        background-color: #fff;
    }
}

.owl-theme {
    .owl-nav {
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        display: block;

        .owl-prev {
            position: absolute;
            content: "";
            bottom: -30px;
            width: 35px;
            height: 35px;
            line-height: 35px;
            left: -30px;
            color: #046292;
            background: #fff;
            font-size: 28px;
            font-weight: 800;
            border-radius: 5px;
            text-align: center;
            -webkit-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;
        }

        .owl-next {
            position: absolute;
            content: "";
            bottom: -30px;
            width: 35px;
            height: 35px;
            line-height: 35px;
            color: #046292;
            background: #ffff;
            font-size: 28px;
            font-weight: 800;
            border-radius: 5px;
            text-align: center;
            -webkit-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;
        }
    }

    .line_rg {
        padding-right: 0;
    }
    .row_rev_sec .row{
        flex-direction: column-reverse;
    }
}

.bx-shadow {
    -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 16%);
    box-shadow: 0 10px 20px rgb(0 0 0 / 16%);
}

/*menu*/
.main-menu ul.sub-menu {
    min-width: 1280px;
    width: 100%;
    transform: translateX(-26%);
    top: calc(100% + 25px) !important;
}

.main-menu nav>ul>li {
    position: unset !important;
}

.main_menu_innera {
    position: relative;

}

.main_menu_inner a::after {
    position: absolute;
    content: '\f105';
    left: 0;
    top: 10px;
    font-size: 25px;
    font-family: "FontAwesome";

}

.main_menu_inner h4 {
    position: relative;
    display: inline-block;
    font-size: 18px;
    margin: 0 0 15px !important;
    text-transform: capitalize;
}

.main_menu_inner h4:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    left: 0;
    background: linear-gradient(90deg, #9d2482, #1513c1);
    bottom: -5px;
}

.sub-menu.submenu {
    padding: 50px 35px!important;
}

.main_menu_inner.mega_menu {
    position: relative;
    display: contents;
}

.main_menu_inner.mega_menu::after {
    content: '';
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #c9c9c9;
    right: 45px;
    top: 0;
}

.mega_menu_set::after {
    content: '';
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #c9c9c9;
    right: 18px;
    top: 0;
}

@property --angle {
    syntax: "<angle>";
    /* Corrected to use <angle> for rotational degrees */
    initial-value: 0deg;
    inherits: false;
}

/* Apply gradient and animation */
.getTouch::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 135px;
    height: 100%;
    z-index: -1;
    transform: translate(-50%, -50%);
    /* Fixed transform syntax */
    padding: 25px 0;
    border-radius: 50px;
    background-image: conic-gradient(from var(--angle), transparent 50%, #9d2482, #1513c1);
    animation: spin 3s infinite linear;
    /* Added linear for smoother spin */
    opacity: 0;
    visibility: hidden;
}

/* Spin animation keyframes */
@keyframes spin {
    from {
        --angle: 0deg;
    }

    to {
        --angle: 360deg;
    }
}

.getTouch:hover::before {
    opacity: 1;
    visibility: visible;
}

.getTouch {
    position: relative;
}

.sub-menu.submenu::after {
    content: "";
    position: absolute;
    background-size: contain;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    width: 120px;
    height: 120px;
    bottom: 10px;
    right: 10px;
    background-image: url(../img/afterbefore/backmenu.webp) !important;

}

.sub_menu {
    font-weight: 700 !important;
}

ul.submenu_inner {
    padding-left: 30px;
}

.readon.buy-now.get-in {
    position: relative;
}

.readon.buy-now.get-in::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 179px;
    height: 100%;
    z-index: -1;
    transform: translate(-50%, -50%);
    padding: 27px 0;
    border-radius: 50px;
    background-image: conic-gradient(from var(--angle), transparent 50%, #9d2482, #1513c1);
    animation: mainse 3s infinite linear;
    opacity: 0;
    visibility: hidden;
}

/* Spin animation keyframes */
@keyframes mainse {
    from {
        --angle: 0deg;
    }

    to {
        --angle: 360deg;
    }
}



/* Spin animation keyframes */
@keyframes mainse {
    from {
        --angle: 0deg;
    }

    to {
        --angle: 360deg;
    }
}

.readon.learn-more:hover::before {
    opacity: 1;
    visibility: visible;
}

.button-wrapt.text-left.md-center {
    position: relative;
    z-index: 5;
}

.set_btnsize a {
    padding: 8px 27px !important;
}

.dig_service .image-part {
    display: none;
}

.item-1 {
    background-image: url(../img/about/BG-2.webp);
    background-position: 100% 0;
    background-size: cover;
}

.item-2 {
    background-image: url(../img/about/BG-3.webp);
    background-position: 100% 0;
    background-size: cover;
}


.accordion {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    overflow: hidden;
    transition: border-color .5s ease;
    background: linear-gradient(90deg, #9d2482, #1513c1);

}

.accordion:hover {
    border-color: #000;
}

.accordion__header {
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem;
    color: #fff;

}

.accordion__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;

    * {
        padding: 0 1em 1em;
        color: #fff;
        font-size: 16px;
    }
}

.accordion input {
    display: none;
}

.qu-service .services-icon {
    display: none;
}

.accordion label {
    margin-bottom: 0;
}

.qu-service li {
    padding: 8px;
    border: 1px dotted #0f4b82;
    margin-bottom: 10px;
    border-radius: 50px;
    color: #000;
    font-size: 13px;
}

.qu-service.rs-services.style3 .services-item:hover.light-purple-bg {
    background: unset;
}

.qu-service.rs-services.style3 .services-item:hover .services-content .services-text .title {
    color: #000;
}

.accordion:has(input:checked) {
    border-color: #000;
    background-color: #fff;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.accordion input:checked~.accordion__header:before {
    transform: rotate(270deg) !important;
    background-color: #fff;
}

.accordion input:checked~.accordion__header:after {
    transform: rotate(270deg) !important;
    background-color: #fff;
}

.accordion input:checked~.accordion__content {
    max-height: 1000px;
    /* Adjust based on content length */
}

.dig_service .services-title {
    text-align: left;
}

.dig_service ul {
    text-align: left;
    margin: 0px 20px 8px 20px;
}


@media only screen and (max-width: 1024px) {
    .main-menu ul.sub-menu {
        min-width: 960px;
        transform: translateX(-26.70%);
    }

    .sub-menu.submenu {
        padding: 35px !important;
    }

    .main_menu_inner.mega_menu::after {
        right: 15px;
    }
    .widget-area_whitepaper .blog-item{
        width: 46% !important;
        margin: 20px 20px 20px 0 !important;
    }
    .widget-area_whitepaper{
        justify-content: end;
    }
    .vedio_lifemetaqualt .video-js{
        margin: 10px auto;
        display: block !important;
        width: 100%;
        background-color: #fff !important;
    }
    .vedio_lifemetaqualt  .video-js .vjs-control-bar{
        margin-bottom: 141px;
    }
    .testimonials .video-js{
        width:100% !important;
        background-color: #fff;
    }
    .testimonials  .video-js .vjs-control-bar{
        bottom:37px;
    }
    .testimonials h4{margin-top: 10px;}
    

}

@media only screen and (max-width: 768px) {
    .tab-area {
        padding: 30px 30px 0 !important;
    }

    .listar-feature-right-border {
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgb(255, 255, 255) 100%);
    }

    .listar-feature-item .listar-feature-item-inner:before {
        box-shadow: none;
    }

    .accordion .btn {
        text-wrap: wrap;
    }

    .Consult {
        margin-bottom: 20px;
    }

    .listar-feature-item .listar-feature-item-title span {
        max-width: 200px;
    }

    .listar-feature-item-wrapper {
        margin-bottom: 120px;
    }
    .whychoos_inner
    {
        display: none;
    }
    .counter_inner_sec{
        padding: 20px 20px !important;
    }
    .rs-process .rs-addon-number .number-text .number-title .title{
        font-size: 16px;
        line-height: 24px;
    }
    .Innovation img.bread_main_img{
        margin: 10px 0 !important;
    }
    .life_metaqualt .sec-title2 .sub-text.style-bg{
        width: 60% !important;
    }
    .widget-area_whitepaper .blog-item{
        width: 100% !important;
        margin: 20px 0 !important;
    }
    .widget-area_whitepaper{
        display: unset !important;
    }
    // .blog-item.mb-44 {
    //     display: unset;
    // }
    .blog-item.mb-44 img{
        width: unset;
        height: unset;
    }
    .testimonials .video-js .vjs-control-bar{
        width: 65% !important;
        left: 120px;
        bottom: 0;
    }
    .testimonials h4{
        margin-top: 20px;
    }
    .testimonials_switch .row{
        flex-direction: column-reverse;
    }
    .testimonials h4{
        margin-top: 20px!important;
    }
    .whitepaper_inner .row{flex-direction:column-reverse;}
    .Author_sec img{margin-bottom: 20px;}
    .Author_sec h3{
        margin: 0 0 10px;
    }
    
}

@media only screen and (max-width: 500px){
    .testimonials .video-js .vjs-control-bar {
        width: 100% !important;
        left: 0;
        bottom:70px;
    }
}

@media only screen and (min-device-width: 720px) and (max-device-width: 1441px) and (orientation: landscape) {
    .listar-feature-item-wrapper {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 160px;
    }

    .listar-feature-item.listar-feature-has-link {
        height: 100%;
    }

    .listar-feature-item a:after {
        bottom: -35px;
    }
    

}

.faq_qu span {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
}

.faq_qu {
    padding: 20px 20px;
    margin: 20px 0 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
}


.faq_ans p {
    margin: 0;
    padding-top: 15px;
}

.faq_ans ul {
    padding-left: 20px;
}


.faq_ans li {
    position: relative;
}

.counter_sec.pt-150.pset.pset {
    z-index: -1;
    position: relative;
}

.artificalint img {
    display: none;
}

ul.quilty_service {
    text-align: left;
    padding-left: 50px;
}

ul.quilty_service li {
    padding: 5px 0;
    position: relative;
}

ul.quilty_service li:after {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #000;
    position: absolute;
    content: '';
    left: -15px;
    top: 14px;
}

.listar-feature-item.listar-feature-has-link {
    align-items: center;
    display: flex;
    justify-content: center;
}

.txt-change .sec-title2 .title {
    -webkit-text-fill-color: #00baff;
}

.mannage {
    padding: 0 0 15px 0 !important;
}

.rs-services.dig_service.main-home .services-item .services-content .services-desc p {
    color: #000;
}

.add-style {
    color: #fff !important;
    background: #ca4a9f;
    display: inline-block !important;
    padding: 5px 11px;
    border-radius: 20px;
}

.counter_sec {
    position: relative;
    z-index: 1;
}

.counter_sec h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 10px;
}

.whychoos {
    background-size: cover;
    background-repeat: no-repeat;

    // padding: 100px 60px;

}

.whychoos .title {
    -webkit-text-fill-color: #fff;
}

.whychoos h3 {
    font-family: "Poppins", sans-serif !important;
    font-size: 48px;
    font-weight: 550;
    line-height: 58px;
    color: #fff;
    position: relative;
}

.whychoos .sec-title2 .desc {
    color: #fff;
}

.line_left {
    text-align: center;
    padding: 20px 0;
    padding-right: 30px;
}

.line_pure {
    width: 4px;
    height: 60%;
    background-color: #ffffff;
    position: absolute;
    right: 52%;
}

.line_rg {
    text-align: center;
    padding: 20px 0;
    padding-right: 30px;

}
.whychoos .style_bg {
    background-color: #fff;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 50px;
    color: #4c2f91;
}

.br_top {
    border-top: 4px solid #fff;
    margin-right: 50px;
    padding-bottom: 30px;
}
.whychoos p {
    color: #fff;
}
.whychoos_inner{
    position: relative;
    min-height: 680px;
}

.whychoos-img{  
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgb(27 53 128), rgba(23, 49, 124, 0)), url(../../assets/img/banner/whychoosimg1.webp);
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    min-height: 680px;
    width: 100%;

}

.counter_sec_des h5{
    min-width: 175px;
    margin: 0 0px 0px 0px;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
}
.listar-feature-icon-inner h2{
color: #fff;
}
.counter_inner{
    border-radius: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 120px;
    background-color:#203985;
}
.counter_inner_sec{
    padding: 80px 20px;
}
 ul.services-hero-inner{
    display: flex;
    padding-bottom: 30px;
}
ul.services-hero-inner li{
    width: 25%;
    text-align: center;
}
ul.services-hero-inner li h5{
    font-weight: 300;
    font-size: 17px;
}


.btn-style510 {
    $btn-color:linear-gradient(to right, #9d2482, #1513c1);;
    $btn-hover-color: linear-gradient(to right, #9d2482, #1513c1);;
    position: relative;
    background-color: $btn-color;
    border-color: $btn-color;
    color: #fff;
    overflow: hidden;
    padding: 8px 5px !important;
    &::before,
    &::after {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100px) skewX(-15deg);
      content: "";
    }
    &::before {
      width: 60px;
      background: rgba(255, 255, 255, 0.5);
      filter: blur(30px);
      opacity: 0.5;
    }
    &::after {
      width: 30px;
      left: 30px;
      background: rgba(255, 255, 255, 0.2);
      filter: blur(5px);
    }
    &:hover {
      background-color: $btn-hover-color;
      border-color: $btn-hover-color;
      &::before {
        opacity: 1;
      }
      &::before,
      &::after {
        transform: translateX(300px) skewX(-15deg);
        transition: all 0.9s ease;
      }
    }
  }
  
.readon {
    $btn-color:linear-gradient(to right, #9d2482, #1513c1);;
    $btn-hover-color: linear-gradient(to right, #9d2482, #1513c1);;
    position: relative;
    background-color: $btn-color;
    border-color: $btn-color;
    color: #fff;
    overflow: hidden;
    &::before,
    &::after {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100px) skewX(-15deg);
      content: "";
    }
    &::before {
      width: 60px;
      background: rgba(255, 255, 255, 0.5);
      filter: blur(30px);
      opacity: 0.5;
    }
    &::after {
      width: 30px;
      left: 30px;
      background: rgba(255, 255, 255, 0.2);
      filter: blur(5px);
    }
    &:hover {
      background-color: $btn-hover-color;
      border-color: $btn-hover-color;
      &::before {
        opacity: 1;
      }
      &::before,
      &::after {
        transform: translateX(300px) skewX(-15deg);
        transition: all 0.9s ease;
      }
    }
  }
  .bg18  .sec-title2 {
        .title {
            font-family: 'Poppins', sans-serif !important;
            font-size: 48px;
            font-weight: 550;
            line-height: 58px;
            background-color: #FF3CAC;
            background: linear-gradient(250deg, #f323a3, #0076bb 70%, #0076bb 0, #f323a3);
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-text-stroke-color: #f5f5fa;
            -webkit-text-fill-color: #00007000;
            max-width: 100%;
            margin: 0 auto;
    
            &.white-color {
                color: $whiteColor;
            }
    
            &.testi-title {
                max-width: unset;
    
                &.new {
                    color: #102B3E;
                }
            } 
        } 
    }
    ul.check-square li span{
    font-weight: 700;
    }
    .bg_change{
        background-color: #fbfbfb;;
    }
    .ToolsTec img{
        display: none;
    }
    .ToolsTec ul{
        text-align: left;
    padding-left: 18px;
    }
    .ToolsTec h3{
       margin: 0;
    }
    .ToolsTec.rs-services.style6 .services-item{
        text-align: left;
    }



.sol_sec{
    box-shadow: 0 6px 25px rgba(12, 89, 219, 0.26);
}










// new csss



  .section {
    padding: 2rem 0;
  }

  
  .swiper {
    width: 100%;
    height: 400px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ddd;
    border-radius: 10px;
    overflow: hidden;
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    background: #000;
    opacity: 0.7;
  }

  .swiper-pagination-bullet-active {
    background: #ff6f61;
    opacity: 1;
  }
.sec_4 p.secondDesc{
   margin-top: 10px;
   color: #fff;
   margin-bottom: 5px;
}

.heading_sec h1{
    font-size: 60px !important;
  }
  .about_meta h4{
   margin: 30px 0 0 20;
  }
  .widget-area_whitepaper{
    display: flex;
    flex-wrap: wrap;
  }
  .widget-area_whitepaper .blog-item{
   width: 29%;
   margin: 30px 20px;
  }
  .home_inner .owl-prev i.fa.fa-angle-left{
    font-size: 20px;
    position: relative;
    top:-5px;
  }
  .home_inner .owl-next i.fa.fa-angle-right{
    font-size: 20px;
    position: relative;
    top:-5px;
  }
  .Portfolio_home_sec .number-title{
    padding-left: 7px;
  }
  .cloud_appli_dev h5{
    margin-bottom: 5px;
  }
  .cloud_appli_dev span{
    color: #000;
  }
.event{
    position: relative;
}
  .gif_set{
    position: relative;
    top:-450px;
    transform: rotate(288deg);
  }
  .gif_set1{
    position: relative;
    top: -400px;
    transform: rotate(347deg);
    left: -70px;
  }


  .marquee {
    width: 100%;
    height: 100%;

    box-shadow: 0 2rem 2rem 0.4rem rgba(0, 0, 0, 0.2);
  }
  
  .marquee__inner-wrap {
    height: 100%;
    width: 100%;
  }
  
  .marquee span {
    text-align: center;
    color: lightgray;
    font-weight: 400;
    white-space: nowrap;
    font-size: max(2vw, 2.4rem);
    line-height: 1.2;
    font-weight: 700;
    padding: 1vh 1vw 0;
    text-transform: uppercase;
  }
  
  .marquee__img {
    width: 200px;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #444;
    filter: grayscale(0.6);
  }
  
  .marquee__inner {
    height: 100%;
    width: fit-content;
    align-items: center;
    display: flex;
    position: relative;
    animation: marquee2 50s linear infinite;
    will-change: transform;
  }
  
  @keyframes marquee2 {
    to {
      transform: translateX(50%);
    }
  }
  


  

  .marquee1 .marquee {
    width: 100%;
    height: 12rem;
    pointer-events: none;
    background: black;
    overflow: hidden;
    box-shadow: 0 2rem 2rem 0.4rem rgba(0, 0, 0, 0.2);
  }
  
  .marquee1 .marquee__inner-wrap {
    height: 100%;
    width: 100%;
  }
  
  .marquee1 .marquee span {
    text-align: center;
    color: lightgray;
    font-weight: 400;
    white-space: nowrap;
    font-size: max(2vw, 2.4rem);
    line-height: 1.2;
    font-weight: 700;
    padding: 1vh 1vw 0;
    text-transform: uppercase;
  }
  
  .marquee1 .marquee__img {
    width: max(8rem, 12vw);
    height: 8rem;
    margin: 0 4vw;
    border-radius: 100rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #444;
    filter: grayscale(0.6);
  }
  
  .marquee1 .marquee__inner {
    height: 100%;
    width: fit-content;
    align-items: center;
    display: flex;
    position: relative;
    animation: marquee1 50s linear infinite;
    will-change: transform;
  }
  
  @keyframes marquee1 {
    to {
      transform: translateX(-50%);
    }
  }
 .event .sec-title2 .sub-text.style-bg{
    margin: 40px auto 10px;
    display: flex;
    justify-content: center;
    width: 30%;

 }
  
  .carousel {
    width: 100%;
    overflow: hidden;   
    border-radius: 10px;
    background: white;
  }
  
  .carousel-track {
    display: flex;
    gap: 30px;
    animation: slide 10s linear infinite;
  }
  
  .carousel img {
    width: 30%;
    height: auto;
  }
  
  /* Animation */
  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .carousel2 {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    background: white;
  }
  
  .carousel-track2 {
    display: flex;
    gap: 30px;
    animation: slide2 10s linear infinite;
  }
  
  .carousel2 img {
    width: 50%;
    height: auto;
  }
  
  /* Animation */
  @keyframes slide2 {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  .bgRed{
    background-color: rgba(112, 86, 143, 0.1); 
  }

  .bgWhite{
    background-color: #fff;
  }
  
  .life_metaqualt img{
    width: 200px;
    height:200px;
  }
.carousel2.life_metaqualt{
    transition: 5s ease-in;
}
.life_metaqualt .sec-title2 .sub-text.style-bg{
    margin: 40px auto 10px;
    display: flex;
    justify-content: center;
    width: 30%;
}
.life_metaqualt .sec-title2 .title{
    text-align: center;
}

.menu-area.menu-sticky.sticky ul.sub-menu.submenu {
    top: calc(100% + 19px) !important;
}
.rs-Testimonials .sec-title2.pb-0.pt-0.md-pt-10.md-pb-20{
    text-align: center;
}
.full-width-header .rs-header.style3 .menu-area .main-menu .rs-menu > ul > li:hover > .sub-menu {
    border-top: 3px solid #4c4c98;
}
.rs-Testimonials h4{
    margin: 0;
}
  .life_meta .sec-title2{
    text-align: center;
  }
  .rs-process .number-title{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
.white-paper h5{
    margin: 0 0 10px;
}
.white-paper h3{
    padding-top: 10px !important;   
}
.input_type .file_sec{
    border: 1px solid  #000 ;
    padding: 5px 20px;
    background-color: #efefef;
}
.Portfolio_home_sec .title{
    margin-left: 10px !important;

}


.whitepaper img{
    width: unset !important;
    height: unset !important;

}
.Innovation img.bread_main_img{
    margin: 20px 0;
}
.home_tab_area .rs-technology .technology-item{
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0 !important;
}
.rs-process.style4 .rs-addon-number .number-text .number-area{
    width: 50px !important;
}
.ui_ux_img img{
    width: 50px;
}
.ui_ux_img1 img{
    width: 150px;
}
.rs-businessmodels .owl-carousel.owl-theme.owl-loaded.owl-drag .owl-next{
    left: 25px;
    line-height: 25px;
}
.rs-businessmodels .owl-carousel.owl-theme.owl-loaded.owl-drag .owl-prev{
    line-height: 25px;
}
.fintech_sol .owl-carousel.owl-theme.owl-loaded.owl-drag .owl-next{
    left: 25px;
    line-height: 25px;
    background-color: #fff;
}
.web_dev p{
    max-width: 600px;
}
p.bold_text {
    margin-top: -10px;
}
a.clr_chnage{
    color:#4c2f91;
}
.vedio_lifemetaqualt {
    outline: none;
}
@media (min-width: 1025px) and (max-width : 2800px){
    .vedio_lifemetaqualt .video-js{
        margin: 10px auto;
        display: block !important;
        width: 100%;
        background-color: #fff !important;
    }
    .vedio_lifemetaqualt .vjs-control-bar{
        margin-bottom: 60px;
    }
}
.video-js .vjs-tech:focus-visible{
    outline: none;
}
@media (max-width: 500px) and (min-width: 390px) {
    .vedio_lifemetaqualt .video-js .vjs-control-bar{
        width: 71% !important;
        left: 57px !important;
        margin-left: 0;
        }
    }
    @media (max-width: 380px) and (min-width: 200px) {
        .vedio_lifemetaqualt .video-js .vjs-control-bar{
            width: 97% !important;
            left: 3px !important;
            margin-left: 0;
            }
        }
        .current-openings_sec h6{
            margin-bottom: 0;
        }
        .rs-faq.current-openings_sec .accodion-style1 .accordion__item .accordion__panel{
            background: #f3f6fb;
           border-radius: 20px;
            position: relative;
            opacity: 1;
            visibility: visible;
            padding-top: 25px;
            display: block !important;
            margin: 0 20px;
         
        }
        .blog_sec.rs-breadcrumbs .breadcrumbs-inner ul{
            max-width: unset;
        }
        .job-detail.ree-card .apply-btn .readon.buy-now.get-in{
            font-size: 16px !important;
        }
        .job-detail.ree-card .apply-btn{
            width: 141px;
        }
        .rs-blog .blog-item .image-wrap{
            width: 40%;
        }
 .blog_inner_sec .blog-item{
    display: flex;
}
.blog_inner_sec .blog-item .blog-content{
    width: 60%;
}

.salesforceSolution li{
    list-style-type:disc !important;
    margin-left: 30px !important;
}



