.page-error{
    background: url(../../assets/img/bg/error.webp);
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    .error-not-found{
        position: relative;
        background: #cfd0ef;
        width: 650px;
        padding: 50px;
        height: 650px;
        border-radius: 50%;
        margin: 0 auto;
        text-align: center;
        .error-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .title{
            font-size: 35px;
            line-height: 52px;
            font-weight: 700;
            color: $titleColor;
            margin-bottom: 30px;
            span{
                font-size: 251px;
                line-height: 171px;
                font-weight: 700;
                color: $titleColor;
                display: block;
                padding-bottom: 70px;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .page-error .error-not-found {
     width: 350px;
     height: 350px;
    }
    .page-error .error-not-found .title span{
        font-size: 130px;
        padding-bottom: 0;
        line-height: 125px;
    }
    .page-error .error-not-found .title{
        line-height: 37px;
    }
    .readon.learn-more{
        padding:8px 13px;
        font-size: 13px;
    }
    .page-error .error-not-found .title{
        margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 320px) {
    .page-error .error-not-found {
     width: 300px;
     height: 300px;
    }
    .page-error .error-not-found .title span{
        font-size: 100px;
        padding-bottom: 0;
        line-height: 120px;
    }
    .page-error .error-not-found .title{
        line-height: 33px;
    }
    .readon.learn-more{
        padding:8px 13px;
        font-size: 12px;
    }
    .page-error .error-not-found .title{
        margin-bottom: 13px;
        font-size: 15px;
        line-height: 20px;
    }
  }