.mobile-menu-part {
  position: absolute;
  left: 0;
  top: -50px;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 11;
  -webkit-transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 600ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  box-shadow: -5px 0 20px -5px rgba(0, 0, 0, 0.5);
  .mobile-menu {
    background-color: #ffffff;
    height: 100vh;
  }
  &.open {
    top: 0;
    height: auto;
    opacity: 1;
    visibility: visible;
  }
}

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: -1;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;

  &.show {
    z-index: 9;
    opacity: 1;
    visibility: visible;
  }
}

.mobile-menu {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      a {
        position: relative;
        display: block;
        font-size: 17px;
        color: $titleColor;
        text-transform: capitalize;
        font-weight: 600;
        height: 50px;
        line-height: 50px;
        padding-left: 25px;
        cursor: pointer;
        &.active-menu,
        &:hover {
          color: $primaryColor;
        }
      }
	
      &.current-menu-item {
        > a {
          color: $primaryColor;
        }
      }
      &:last-child {
        border: none;
      }
    }

    li.menu-item-has-children {
      > a {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          line-height: 50px;
          background: #fff;
        }
        &:after {
          position: absolute;
          content: "";
          width: 8px;
          height: 8px;
          border-width: 2px 0 0 2px;
          border-style: solid;
          border-color: initial;
          color: $bodyColor;
          right: 22px;
          top: 50%;
          -webkit-transform: rotate(-45deg) translateY(-50%);
          transform: rotate(-135deg) translateY(-50%);
          -webkit-transform-origin: top;
          transform-origin: top;
          transition: all 0.3s ease-out;
        }
      }
      ul.sub-menu {
        height: 0;
        opacity: 0;
        visibility: hidden;
        list-style: none;
        li {
          border-bottom: none;
          &:hover {
            > a {
              color: $primaryColor;
            }
          }
          a {
            position: relative;
            padding-left: 40px;
            font-size: 13px;
            transition: all 0.3s;
          }
        }
      }
    }

    li.menu-item-has-children.current-menu-item {
      > a {
        color: $primaryColor;
        &:after {
          -webkit-transform: rotate(-135deg) translateY(-50%);
          transform: rotate(45deg) translateY(-50%);
        }
      }
      ul.sub-menu.current-menu {
        height: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.rs-get-in {
  padding-left: 0 !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  color: #fff !important;
  font-family: "Poppins", sans-serif !important;
  background: linear-gradient(to right, #9d2482, #1513c1);
  border-radius: 30px;
  display: inline !important;
  &:hover {
    background: #03228f;
    color: $whiteColor;
    border: 1px solid #03228f;
  }
}
@media only screen and (max-width: 768px) {
  .main_menu_inner h4 {
    font-size: 15px;
    margin: 0 0 15px;
  }
  .mobile-menu
    ul
    li.menu-item-has-children.current-menu-item
    ul.sub-menu.current-menu {
    padding: 10px 30px 0px;
  }
  .main_menu_inner a::after {
    font-size: 17px;
    top: 0;
  }
  .mobile-menu ul li.menu-item-has-children ul.sub-menu li a {
    padding-left: 30px;
  }
  .mobile-menu ul li.menu-item-has-children ul.sub-menu li a {
    line-height: 30px !important;
    height: unset !important;
  }
  .mega_menu_set::after {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .mobile-menu-part {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  }
}
.menu-area.menu-sticky.active {
  background-color: #fff !important;
}
.mobile-menu-part.open {
  background-color: #fff;
}
